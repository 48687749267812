<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('我的消息')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <van-pull-refresh
                    :loosing-text="$t('释放即可刷新')"
                    :pulling-text="$t('下拉即可刷新')"
                    :loading-text="$t('加载中')"
                    v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <div class="wt-card" v-for="(item, index) in list" :key="index"
                         @click="toDetail(item)">
                        <div class="msg-body flex-center">
                            <div class="msg-icon">
                                <van-icon color="#56d6f1" v-if="item.mark == 0" name="bell" size="18" dot />
                                <van-icon color="#56d6f1" v-else name="bell" size="18" />
                            </div>
                            <div class="flex_bd">
                                <div class="msg-title">
                                    {{$t(item.title)}}
                                </div>
                                <div class="msg-foot">{{item.created_at}}</div>
                            </div>
                        </div>
                        
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>

    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: '',
                show: false,
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            toDetail(item) {
                this.$router.push({path: '/message-detail', query: {id: item.id}})
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$axios
                    .get('/user/message_list' + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        },
    }
</script>
<style scoped="scoped">
    .msg-badge {
        position: relative;
        top: -10px;
        left: -15px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background-color: #0014cd;
        color: #FFFFFF;
        font-size: 0.24155rem;
    }

    .msg-list {
        border-radius: 8px;
        background: #f3f6fb;
        margin-top: 20px;
        font-size: 14px;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pd-20 {
        padding: 15px 10px;
    }
    .msg-title{
        display: flex;
    }
    .msg-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #2b3640;
        border: 1px solid #56d6f1;
        color: #56d6f1;
        margin-right: 10px;
    }
    .text-7a8 {
        text-align: right;
        width: 160px;
        color: #7A869A;
    }

    .moretb, .tit {
        font-size: 14px;
        color: #101010;
        width: 200px;
        padding-left: 10px;
    }

    .mt10 {
        margin-top: 10px;
    }

    .bortopr {
        border-top: dashed 1px #979797;
    }
</style>